h1 {
  font-family: 'Times';
  font-weight: 100;
  font-size: 4.25em;
  margin: 0px;
}

h2 {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 1.65em;
  margin: 0px;
}